<template>
  <div>
    <el-select v-model="timeZone" placeholder="请选择">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
    {{ currentTime }}
  </div>
</template>

<script>
export default {
  name: 'YdTime',
  data() {
    return {
      currentTime: '',
      options: [{
        value: 'Asia/Kolkata',
        label: '印度'
      },
        {
          value: 'Asia/Shanghai',
          label: '中国'
        },
      ],
      timeZone: 'Asia/Kolkata'
    };
  },
  mounted() {
    setInterval(() => {
      let date = new Date().toLocaleString("en-US", {
        timeZone: this.timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false // 将小时转为24小时制
      });
      this.currentTime = date.replace(",", "");
    }, 1000);
  }
};
</script>
