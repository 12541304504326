var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: { clearable: "", filterable: "", placeholder: "请选择" },
      on: { change: _vm.selectChange },
      model: {
        value: _vm.form.type,
        callback: function ($$v) {
          _vm.$set(_vm.form, "type", $$v)
        },
        expression: "form.type",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }