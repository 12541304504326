<template>
    <el-dialog :title="title" :visible.sync="visible" width="500px" append-to-body>
        <el-form ref="form" :model="form" label-width="100px" :rules="rules">
            <el-form-item label="商户名称" prop="merchantIds" style="width: 100%;">
                <el-select v-model="form.merchantIds" multiple style="width: 100%;" clearable>
                    <el-option v-for="merchant in merchants" :key="merchant.value" :label="`${merchant.label}`"
                        :value="merchant.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="消息内容" prop="content">
                <el-input v-model="form.content" type="textarea" placeholder="请输入消息内容" />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { sendTgMessage } from "@/api/merchant/merchant"; 
import {getMerchants} from "@/api/query/variable";

export default {
    name: "SendTgMessage",
    props: {
        // merchants: {
        //     type: Array,
        //     default: []
        // }
    },
    data() {
        return {
            visible: false,
            merchants: [],
            title: "发送TG消息",
            form: {
                merchantIds: [],
                content: ""
            },
            rules: {
                content: [
                    { required: true, message: "请输入消息内容", trigger: "blur" }
                ],
                merchantIds: [
                    { required: true, message: "请选择商户", trigger: "blur" }
                ]
            }
        };
    },
    mounted() {
        this.form.merchantIds = this.merchants.map(merchant => merchant.id);
    },
    methods: {
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    sendTgMessage(this.form).then(response => {
                        this.$message.success("消息发送成功");
                        this.cancel();
                    }).catch(error => {
                        this.$message.error("消息发送失败");
                    });
                }
            });
        },
        cancel() {
            this.visible = false;
        },
        open(chosenIds) {
            getMerchants().then(response => {
                this.merchants = response.data;
            }); 
            this.form.merchantIds = chosenIds;
            this.form.content = "";
            this.visible = true;
        }
    },
};
</script>

<style scoped>
.dialog-footer {
    text-align: right;
}
</style>
