<template>
  <el-select v-model="form.queryType" filterable placeholder="请选择">
    <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
import {channelQueryType} from "@/api/query/constant";

export default {
  name: "ChannelQueryType",
  props: ['form'],
  data() {
    return {
      options: [],
    }
  },
  created() {
    channelQueryType().then(response => {
      this.options = response.data
    });
  },
}
</script>
