import request from '@/utils/request'

var baseHost = '/query/constant/'

// 查询日通道余额列表
export function settleCycle(query) {
    return request({
        url: baseHost + 'settleCycle',
        method: 'get', params: query
    })
}

// 查询回调类型
export function channelQueryType(query) {
    return request({
        url: baseHost + 'channelQueryType',
        method: 'get', params: query
    })
}

// 查询通道类型
export function channelType(query) {
    return request({
        url: baseHost + 'channelType',
        method: 'get', params: query
    })
}

// 代收状态列表
export function payInStatus(query) {
    return request({
        url: baseHost + 'payInStatus',
        method: 'get', params: query
    })
}

// 代付状态列表
export function payOutStatus(query) {
    return request({
        url: baseHost + 'payOutStatus',
        method: 'get', params: query
    })
}


// 代付状态列表
export function merchantBalanceOperateType(query) {
    return request({
        url: baseHost + 'merchantBalanceOperateType',
        method: 'get', params: query
    })
}

export function getTimeType(query) {
    return request({
        url: baseHost + 'getTimeType',
        method: 'get', params: query
    })
}