var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      staticClass: "upload-demo",
      attrs: {
        action: "#",
        "on-preview": _vm.handlePreview,
        "on-remove": _vm.handleRemove,
        "before-remove": _vm.beforeRemove,
        multiple: "",
        limit: 1,
        "on-exceed": _vm.handleExceed,
        "http-request": _vm.httpRequest,
        "file-list": _vm.fileList,
      },
    },
    [
      _c("el-button", { attrs: { size: "small", type: "primary" } }, [
        _vm._v("上传cranesPay回调(csv)"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }