<template>
<div style="display: inline-block;">
    <el-select v-if="!alisa" v-model="form.channelNo" clearable filterable placeholder="请选择" @change="selectChange">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
    <el-select v-if="alisa&&alisa=='collectionDefaultChannel'" v-model="form.collectionDefaultChannel" clearable
               filterable placeholder="请选择" @change="selectChange">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
    <el-select v-if="alisa&&alisa=='paymentDefaultChannel'" v-model="form.paymentDefaultChannel" clearable filterable
               placeholder="请选择" @change="selectChange">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {getAllChannel} from "@/api/query/variable";

export default {
  name: "AllChannel",
  props: ['form', 'type', 'alisa'],
  data() {
    return {
      options: [],
    }
  },
  created() {
    getAllChannel({'type': this.type}).then(response => {
      this.options = response.data
    });
  },
  methods: {
    selectChange(value) {
      this.$emit('dataScopeSelectChange', value)
    }
  }
}
</script>
