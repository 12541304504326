<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px">
      <all-channel :form="form" type="payment"/>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import {changeChannelNo} from "@/api/order/payment";
import AllChannel from "@/components/Query/AllChannel.vue";

export default {
  name: "changeChannelNo",
  components: {AllChannel},
  props: ['info', 'title', 'ids'],
  data() {
    return {
      form: {},
    };
  },
  methods: {
    submitForm: function () {
      if (this.ids.length == 0) {
        this.$modal.msgWarning("未选中订单");
        return
      }
      if (!this.form.channelNo) {
        this.$modal.msgWarning("未选择通道");
        return
      }
      changeChannelNo(this.form.channelNo, this.ids).then(res => {
        this.$modal.msgSuccess(res.msg);
        this.cancel()
      }).catch(err => {
      })
      console.log(this.form)
      console.log(this.ids)
    },
    cancel: function () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
