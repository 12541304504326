<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="操作类型" prop="channelNo">
        <merchant-balance-operate-type :form="form"/>
      </el-form-item>
      <el-form-item label="金额" prop="amount">
        <el-input v-model="form.amount" placeholder="请输入金额" type="number"/>
      </el-form-item>
      <el-form-item label="备注" prop="amount">
        <el-input v-model="form.remark" placeholder="请输入备注"/>
      </el-form-item>
      <el-button type="success" @click="submit">提交</el-button>
      <el-button @click="cancel">取 消</el-button>
    </el-form>
  </div>
</template>

<script>
import MerchantList from "@/components/Query/merchantList";
import MerchantBalanceOperateType from "@/components/Query/MerchantBalanceOperateType";
import {updateBalance} from "@/api/merchant/merchantBalance";

export default {
  name: "balance",
  components: {MerchantBalanceOperateType, MerchantList},
  props: ['merchantId'],
  data() {
    return {
      rateList: [],
      // 选中数组
      items: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      form: {
        amount: null,
        operateType: null,
        merchantId: this.merchantId,
      }
    }
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.items = selection.map(item => item)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    submit() {
      updateBalance(this.form).then(res => {
        this.$modal.msgSuccess("操作成功");
        this.cancel()
      })
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
