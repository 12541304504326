var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "500px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "100px", rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "商户名称", prop: "merchantIds" },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { multiple: "", clearable: "" },
                  model: {
                    value: _vm.form.merchantIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "merchantIds", $$v)
                    },
                    expression: "form.merchantIds",
                  },
                },
                _vm._l(_vm.merchants, function (merchant) {
                  return _c("el-option", {
                    key: merchant.value,
                    attrs: {
                      label: "" + merchant.label,
                      value: merchant.value,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.type === "collection"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "代收通道号",
                    prop: "collectionDefaultChannel",
                  },
                },
                [
                  _c("pay-out-channel", {
                    attrs: {
                      form: _vm.form,
                      alisa: "collectionDefaultChannel",
                      type: "collection",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.type === "payment"
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "代付通道号", prop: "paymentDefaultChannel" },
                },
                [
                  _c("all-channel", {
                    attrs: {
                      form: _vm.form,
                      alisa: "paymentDefaultChannel",
                      type: "payment",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
            [_vm._v("确 定")]
          ),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }