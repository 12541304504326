<template>
  <el-select v-model="form.type" clearable filterable placeholder="请选择" @change="selectChange">
    <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
import {channelType} from "@/api/query/constant";

export default {
  name: "ChannelType",
  props: ['form'],
  data() {
    return {
      options: [],
    }
  },
  created() {
    channelType().then(response => {
      this.options = response.data
    });
  },
  methods: {
    selectChange(value) {
      this.$emit('dataScopeSelectChange', value)
    }
  }
}
</script>
