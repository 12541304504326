var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      attrs: {
        limit: 1,
        accept: ".xls",
        action: _vm.upload.url,
        headers: _vm.upload.headers,
        "file-list": _vm.upload.fileList,
        "on-progress": _vm.handleFileUploadProgress,
        "on-success": _vm.handleFileSuccess,
        "auto-upload": false,
      },
    },
    [
      _c(
        "el-button",
        {
          attrs: { slot: "trigger", size: "small", type: "primary" },
          slot: "trigger",
        },
        [_vm._v("上传代付(xls)")]
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "10px" },
          attrs: {
            size: "small",
            type: "success",
            loading: _vm.upload.isUploading,
          },
          on: { click: _vm.submitUpload },
        },
        [_vm._v("确认")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }