import request from '@/utils/request'

var baseHost = '/query/variable/'

// 商家列表
export function getMerchants(query) {
    return request({
        url: baseHost + 'getMerchants',
        method: 'get', params: query
    })
}

// 商家列表
export function getAllChannel(query) {
    return request({
        url: baseHost + 'getAllChannel',
        method: 'get', params: query
    })
}

// 获取upi账号
export function getUpiList(query) {
    return request({
        url: baseHost + 'getUpiList',
        method: 'get', params: query
    })
}


