<template>
    <el-dialog :title="title" :visible.sync="visible" width="500px" append-to-body>
        <el-form ref="form" :model="form" label-width="100px" :rules="rules">
            <el-form-item label="商户名称" prop="merchantIds" style="width: 100%;">
                <el-select v-model="form.merchantIds" multiple style="width: 100%;" clearable>
                    <el-option v-for="merchant in merchants" :key="merchant.value" :label="`${merchant.label}`"
                        :value="merchant.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="代收通道号" prop="collectionDefaultChannel" v-if="type === 'collection'">
                <pay-out-channel :form="form" alisa="collectionDefaultChannel" type="collection" />
            </el-form-item>
            <el-form-item label="代付通道号" prop="paymentDefaultChannel" v-if="type === 'payment'">
                <all-channel :form="form" alisa="paymentDefaultChannel" type="payment" />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { batchSetCollectionChannel, batchSetPaymentChannel } from "@/api/merchant/merchant";
import { getMerchants } from "@/api/query/variable";
import PayOutChannel from "@/components/Query/PayOutChannel.vue";
import AllChannel from "@/components/Query/AllChannel.vue";

export default {
    name: "BatchSetChannel",
    components: {
        PayOutChannel,
        AllChannel
    },
    props: {
        // merchants: {
        //     type: Array,
        //     default: []
        // },
        type: {
            type: String,
            default: "collection"
        }
    },
    data() {
        return {
            visible: false,
            merchants: [],
            title: "批量设置" + (this.type === 'collection' ? '代收' : '代付'),
            form: {
                merchantIds: [],
                collectionDefaultChannel: [],
                paymentDefaultChannel: ''
            },
            rules: {
                // collectionDefaultChannel: [
                //     { required: true, message: "请选择代收通道号", trigger: "blur" }
                // ],
                // paymentDefaultChannel: [
                //     { required: true, message: "请选择代付通道号", trigger: "blur" }
                // ],
                merchantIds: [
                    { required: true, message: "请选择商户", trigger: "blur" }
                ]
            }
        };
    },
    mounted() {
        this.form.merchantIds = this.merchants.map(merchant => merchant.id);
    },
    methods: {
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    if (this.type === 'collection') {
                        batchSetCollectionChannel({
                            merchantIds: this.form.merchantIds,
                            collectionChannel: this.form.collectionDefaultChannel.join(",")
                        }).then(response => {
                            this.$message.success("批量设置成功");
                            this.$emit("refresh");
                            this.cancel();
                        }).catch(error => {
                            this.$message.error("批量设置失败");
                        });
                    } else if (this.type === 'payment') {
                        batchSetPaymentChannel({
                            merchantIds: this.form.merchantIds,
                            paymentChannel: this.form.paymentDefaultChannel
                        }).then(response => {
                            this.$message.success("批量设置成功");
                            this.$emit("refresh");
                            this.cancel();
                        }).catch(error => {
                            this.$message.error("批量设置失败");
                        });
                    }
                }
            });
        },
        cancel() {
            this.visible = false;
        },
        async open(chosenIds) {
            await getMerchants().then(response => {
                this.merchants = response.data;
            });
            this.form.merchantIds = chosenIds;
            this.form.collectionDefaultChannel = [];
            this.form.paymentDefaultChannel = '';
            this.visible = true;
        }
    },
};
</script>

<style scoped>
.dialog-footer {
    text-align: right;
}
</style>
