<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="平台订单号" prop="orderNo">
        <el-input size="medium" v-model="form.orderNo" placeholder="请输入平台订单号" clearable/>
      </el-form-item>
      <el-form-item label="订单状态" prop="status">
        <el-select v-model="form.status" clearable filterable placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import {cranesPayCallback} from "@/api/order/payment";

export default {
  name: "cranesPayCallback",
  props: ['info', 'title'],
  data() {
    return {
      form: {},
      options: [
        {
          'label': '成功',
          'value': 'SUCCESS',
        },
        {
          'label': '失败',
          'value': 'FAIELD',
        }
      ]
    };
  },
  methods: {
    submitForm: function () {
      cranesPayCallback(this.form).then(res => {
        this.$modal.msgSuccess(res.msg);
        this.cancel()
      })
    },
    cancel: function () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
