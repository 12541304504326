import request from '@/utils/request'

// 查询商家列表
export function listMerchant(query) {
  return request({
    url: '/merchant/merchant/list',
    method: 'get',
    params: query
  })
}

// 查询商家详细
export function getMerchant(id) {
  return request({
    url: '/merchant/merchant/' + id,
    method: 'get'
  })
}

// 新增商家
export function addMerchant(data) {
  return request({
    url: '/merchant/merchant',
    method: 'post',
    data: data
  })
}

// 修改商家
export function updateMerchant(data) {
  return request({
    url: '/merchant/merchant',
    method: 'put',
    data: data
  })
}

// 删除商家
export function delMerchant(id) {
  return request({
    url: '/merchant/merchant/' + id,
    method: 'delete'
  })
}

// 发送TG消息
export function sendTgMessage(data) {
  return request({
    url: '/merchant/merchant/sendTgMessage',
    method: 'put',
    data: data
  })
}
// 批量设置渠道号
export function batchSetChannel(data) {
  return request({
    url: '/merchant/merchant/batchSetChannel',
    method: 'put',
    data: data
  })
}

// 批量设置渠道号
export function batchSetCollectionChannel(data) {
  return request({
    url: '/merchant/merchant/batchSetCollectionChannel',
    method: 'put',
    data: data
  })
}

// 批量设置渠道号
export function batchSetPaymentChannel(data) {
  return request({
    url: '/merchant/merchant/batchSetPaymentChannel',
    method: 'put',
    data: data
  })
}

