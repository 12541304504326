<template>
  <div style="display: inline-block;">
    <el-date-picker
        v-model="queryParams.searchTime"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        :picker-options="pickerOptions"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
    </el-date-picker>
  </div>
</template>

<script>

export default {
  name: "searchTime",
  props: ['queryParams'],
  data() {
    return {
      options: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              // 假设 this.timeZone 是你所需的时区，例如 'Asia/Kolkata'
              // const timeZone = 'Asia/Shanghai'; // 替换为你的时区
              // 创建当前日期对象
              const now = new Date();
              // 创建一个新的日期对象，将时间设为00:00:00
              const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
              const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
              // 使用 toLocaleString 方法获取今天开始时间，并指定时区
              picker.$emit('pick', [start, end]);
            }
          }
          , {
            text: '昨天',
            onClick(picker) {
              // 假设 this.timeZone 是你所需的时区，例如 'Asia/Kolkata'
              // const timeZone = 'Asia/Shanghai'; // 替换为你的时区
              // 创建当前日期对象
              const now = new Date();
              // 创建一个新的日期对象，将时间设为00:00:00
              const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
              const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近三天',
            onClick(picker) {
              // 假设 this.timeZone 是你所需的时区，例如 'Asia/Kolkata'
              // const timeZone = 'Asia/Shanghai'; // 替换为你的时区
              // 创建当前日期对象
              const now = new Date();
              // 创建一个新的日期对象，将时间设为00:00:00
              const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
              const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              // 假设 this.timeZone 是你所需的时区，例如 'Asia/Kolkata'
              // const timeZone = 'Asia/Shanghai'; // 替换为你的时区
              // 创建当前日期对象
              const now = new Date();
              // 创建一个新的日期对象，将时间设为00:00:00
              const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
              const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              // 假设 this.timeZone 是你所需的时区，例如 'Asia/Kolkata'
              // const timeZone = 'Asia/Shanghai'; // 替换为你的时区
              // 创建当前日期对象
              const now = new Date();
              // 创建一个新的日期对象，将时间设为00:00:00
              const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
              const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              // 假设 this.timeZone 是你所需的时区，例如 'Asia/Kolkata'
              // const timeZone = 'Asia/Shanghai'; // 替换为你的时区
              // 创建当前日期对象
              const now = new Date();
              // 创建一个新的日期对象，将时间设为00:00:00
              const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
              const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      }
    }
  },
  created() {
    // 假设 this.timeZone 是你所需的时区，例如 'Asia/Kolkata'
    // const timeZone = 'Asia/Shanghai'; // 替换为你的时区
    // 创建当前日期对象
    const now = new Date();
    // 创建一个新的日期对象，将时间设为00:00:00
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    this.queryParams.searchTime = [
      start, end
    ]
  },
  methods: {
    selectChange(value) {
      this.$emit('dataScopeSelectChange', value)
    }
  }
}
</script>
