<template>
  <el-upload
      ref="upload"
      :limit="1"
      accept=".xls"
      :action="upload.url"
      :headers="upload.headers"
      :file-list="upload.fileList"
      :on-progress="handleFileUploadProgress"
      :on-success="handleFileSuccess"
      :auto-upload="false">
    <el-button slot="trigger" size="small" type="primary">上传代付(xls)</el-button>
    <el-button style="margin-left: 10px;" size="small" type="success" :loading="upload.isUploading" @click="submitUpload">确认</el-button>
  </el-upload>
</template>

<script>
import {getToken} from "@/utils/auth";

export default {
  name: "UploadPayOut",
  data() {
    return {
      // 上传参数
      upload: {
        // 是否禁用上传
        isUploading: false,
        // 设置上传的请求头部
        headers: {Authorization: "Bearer " + getToken()},
        // 上传的地址
        url: process.env.VUE_APP_ENV_BASE_URL + "/order/payment/uploadPayOut",
        // 上传的文件列表
        fileList: []
      }
    };
  },
  methods: {
    handleAdd() {
      this.upload.fileList = [];
    },
    handleUpdate(row) {
      this.upload.fileList = [{name: this.form.fileName, url: this.form.filePath}];
    },
    // 文件提交处理
    submitUpload() {
      this.$refs.upload.submit();
    },
// 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
// 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.isUploading = false;
      this.form.filePath = response.url;
      this.msgSuccess(response.msg);
    }
  }
}
</script>

<style scoped>

</style>
