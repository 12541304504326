<template>
  <div style="display: inline-block;">
    <el-select v-model="form.status" clearable filterable placeholder="请选择" @change="selectChange">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {payInStatus, payOutStatus} from "@/api/query/constant";

export default {
  name: "OrderStatusList",
  props: ['form', 'searchType'],
  data() {
    return {
      options: [],

    }
  },
  created() {
    let searchMethod = payInStatus
    if (this.searchType == 'payOut') {
      searchMethod = payOutStatus
    }
    searchMethod().then(response => {
      this.options = response.data
    });
  },
  methods: {
    selectChange(value) {
      this.$emit('dataScopeSelectChange', value)
    }
  }
}
</script>
