<template>
  <el-upload
      class="upload-demo"
      action="#"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      multiple
      :limit="1"
      :on-exceed="handleExceed"
      :http-request="httpRequest"
      :file-list="fileList">
    <el-button size="small" type="primary">上传cranesPay回调(csv)</el-button>
  </el-upload>
</template>

<script>
import {uploadCranesPayCallback} from "@/api/order/payment";

export default {
  name: "cranesPayUploadCallback",
  data() {
    return {
      fileList: []
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    httpRequest(param) {
      console.log(param)
      const reader = new FileReader();
      //date,valueDate,chqNo,narration,cod,debit,credit,balance
      let items = []
      reader.onload = () => {
        let datas = reader.result.split('\n');
        for (let i = datas.length - 1; i >= 0; i--) {
          if (i == 0) {
            let titles = datas[i].split(',')
            console.log(titles.join(','))
            continue
          }
          if (datas[i].length == 0) {
            continue
          }
          let item = {}
          let data = datas[i].split(',');
          item['ID'] = data[0]
          item['Date Time'] = data[1]
          item['User Details'] = data[2]
          item['Txn Type'] = data[3]
          item['Txn ID'] = data[4]
          item['UTR No'] = data[5]
          item['Reference Details'] = data[6]
          item['Amount'] = data[7]
          item['Charge'] = data[8]
          item['Credit'] = data[9]
          item['Debit'] = data[10]
          item['Status'] = data[11]
          item['Status'] = item['Status'].replace('\r', '')
          if (item['Status'] != 'success') {
            console.log(item)
            continue
          }
          items.push(item)
        }
        console.log(items.length)
        uploadCranesPayCallback(items).then(res => {
          this.$modal.msgSuccess("上传成功");
        })
        this.fileList = []
      };
      reader.readAsText(param.file);
    }
  }
}
</script>

<style scoped>

</style>
