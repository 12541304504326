<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="商家名称" prop="merchantId">
        <merchant-list :form="queryParams" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-message" size="mini" @click="handleSendTgMessage">发送TG消息
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-s-flag" size="mini" @click="handleBatchSetCollectionChannel">设置代收渠道
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-s-flag" size="mini" @click="handleBatchSetPaymentChannel">设置代付渠道
        </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-dialog title="操作余额" :visible.sync="balanceItem.show" width="500px" append-to-body>
      <balance :merchantId="balanceItem.merchantId" @cancel="balanceItem.merchantId = undefined; balanceItem.show = false"
        :key="balanceItem.key" />
    </el-dialog>

    <SendTgMessage  ref="sendTgMessageRef" />
    <BatchSetChannel ref="batchSetCollectionChannelRef" @refresh="getList" type="collection"/>
    <BatchSetChannel  ref="batchSetPaymentChannelRef" @refresh="getList" type="payment"/>

    <el-table v-loading="loading" :data="merchantList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="商家id" align="center" prop="id" :show-overflow-tooltip="true" />
      <el-table-column label="商家key" align="center" prop="keyCode" :show-overflow-tooltip="true" />
      <el-table-column label="商家名称" align="center" prop="name" width="80" />
      <el-table-column label="邮箱号" align="center" prop="email" width="200" />
      <el-table-column label="代付费率" align="center" prop="paymentChannelRate" width="80" />
      <el-table-column label="代收费率" align="center" prop="collectionChannelRate" width="80" />
      <el-table-column label="代付单笔费率" align="center" prop="singleFee" width="120" />
      <!-- <el-table-column label="状态" align="center" prop="status" width="60">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.status"
              disabled
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </template>
</el-table-column> -->
      <el-table-column label="余额" align="center" prop="totalBalance" width="100" />
      <el-table-column label="可用余额" align="center" prop="available" width="100" />
      <el-table-column label="冻结余额" align="center" prop="freeze" width="100" />
      <el-table-column label="代收通道号" align="center" prop="collectionDefaultChannel" width="120" />
      <el-table-column label="代付通道号" align="center" prop="paymentDefaultChannel" width="120" />
      <el-table-column label="强制默认通道" align="center" prop="useStatus" width="120">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.useStatus" disabled active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建人" align="center" prop="createdBy" width="80" />
      <el-table-column label="创建时间" align="center" prop="createdTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createdTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新人" align="center" prop="updatedBy" width="80" />
      <el-table-column label="更新时间" align="center" prop="updatedTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updatedTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" class-name="small-padding fixed-width" fixed="right" width="80">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
            @change="handleStatusChange(scope.row)">
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right" width="130">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-edit"
            @click="balanceItem.show = true; balanceItem.merchantId = scope.row.id; balanceItem.key = balanceItem.key + 1">操作余额
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
      @pagination="getList" />

    <!-- 添加或修改商家对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="商家名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入商家名称" />
        </el-form-item>
        <el-form-item label="邮箱号" prop="email">
          <el-input v-model="form.email" placeholder="请输入邮箱号" />
        </el-form-item>
        <el-form-item label="代收费率" prop="collectionChannelRate">
          <el-input v-model="form.collectionChannelRate" placeholder="请输入代收费率" />
        </el-form-item>
        <el-form-item label="代付费率" prop="paymentChannelRate">
          <el-input v-model="form.paymentChannelRate" placeholder="请输入代付费率" />
        </el-form-item>
        <el-form-item label="代付单笔费率" prop="singleFee">
          <el-input v-model="form.singleFee" placeholder="请输入代付单笔费率" />
        </el-form-item>
        <el-form-item label="代收通道号" prop="collectionDefaultChannel">
          <pay-out-channel :form="form" alisa="collectionDefaultChannel" type="collection" />
        </el-form-item>
        <el-form-item label="代付通道号" prop="paymentDefaultChannel">
          <pay-out-channel :form="form" alisa="paymentDefaultChannel" type="payment" />
        </el-form-item>
        <el-form-item label="白名单" prop="ips">
          <el-input v-model="form.ips" type="textarea" placeholder="请输入内容" />
        </el-form-item>
        <!--        <el-form-item label="强制默认通道" prop="useStatus">-->
        <!--          <el-switch-->
        <!--              v-model="form.useStatus"-->
        <!--              active-color="#13ce66"-->
        <!--              inactive-color="#ff4949">-->
        <!--          </el-switch>-->
        <!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listMerchant, getMerchant, delMerchant, addMerchant, updateMerchant } from "@/api/merchant/merchant";
import MerchantList from "@/components/Query/merchantList";
import ChannelType from "@/components/Query/ChannelType";
import ChannelQueryType from "@/components/Query/ChannelQueryType";
import AllChannel from "@/components/Query/AllChannel";
import Balance from "@/views/merchant/list/balance";
import Payment from "@/views/order/payment";
import PayOutChannel from "@/components/Query/PayOutChannel.vue";
import SendTgMessage from "@/views/merchant/list/SendTgMessage.vue";
import BatchSetChannel from "@/views/merchant/list/BatchSetChannel.vue";

export default {
  name: "Merchant",
  components: { PayOutChannel, Payment, Balance, ChannelType, ChannelQueryType, AllChannel, MerchantList, SendTgMessage, BatchSetChannel },
  data() {
    return {
      balanceItem: {
        merchantId: undefined,
        amount: undefined,
        show: false,
        key: 0
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 商家表格数据
      merchantList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyCode: null,
        openssl: null,
        paymentChannelRate: null,
        collectionChannelRate: null,
        singleFee: null,
        createdBy: null,
        createdTime: null,
        updatedBy: null,
        updatedTime: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        createdTime: [
          { required: true, message: "创建时间不能为空", trigger: "blur" }
        ],
        updatedTime: [
          { required: true, message: "更新时间不能为空", trigger: "blur" }
        ]
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询商家列表 */
    getList() {
      this.loading = true;
      this.queryParams.id = this.queryParams.merchantId
      listMerchant(this.queryParams).then(response => {
        this.merchantList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        keyCode: null,
        openssl: null,
        paymentChannelRate: null,
        collectionChannelRate: null,
        singleFee: null,
        createdBy: null,
        createdTime: null,
        updatedBy: null,
        updatedTime: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加商家";
    },
    // 发送TG消息
    handleSendTgMessage() {
      this.$refs.sendTgMessageRef.open(this.ids);
    },
    // 批量设置渠道号
    handleBatchSetChannel() {
      this.$refs.batchSetChannelRef.open(this.ids);
    },
    // 批量设置代收通道号
    handleBatchSetCollectionChannel() {
      this.$refs.batchSetCollectionChannelRef.open(this.ids);
    },
    // 批量设置代付通道号
    handleBatchSetPaymentChannel() {
      this.$refs.batchSetPaymentChannelRef.open(this.ids);
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getMerchant(id).then(response => {
        this.form = response.data;
        try {
          this.form.collectionDefaultChannel = this.form.collectionDefaultChannel.split(',')
          this.form.paymentDefaultChannel = this.form.paymentDefaultChannel.split(',')
        } catch (e) {
        }
        this.open = true;
        this.title = "修改商家";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            try {
              this.form.collectionDefaultChannel = this.form.collectionDefaultChannel.join(',')
              this.form.paymentDefaultChannel = this.form.paymentDefaultChannel.join(',')
            } catch (e) {
            }
            updateMerchant(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            try {
              this.form.collectionDefaultChannel = this.form.collectionDefaultChannel.join(',')
              this.form.paymentDefaultChannel = this.form.paymentDefaultChannel.join(',')
            } catch (e) {
            }
            addMerchant(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认删除商家编号为"' + ids + '"的数据项？').then(function () {
        return delMerchant(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('ruoyi/merchant/export', {
        ...this.queryParams
      }, `merchant_${new Date().getTime()}.xlsx`)
    },
    handleStatusChange(row) {
      getMerchant(row.id).then(response => {
        this.form = response.data;
        this.form.status = row.status ? 'Y' : 'N';
        updateMerchant(this.form).then(response => {
          this.getList();
          this.$modal.msgSuccess("修改成功");
        });
      });
    },
  }
};
</script>
