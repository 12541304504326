<template>
  <div>
    <el-form :model="form" ref="queryForm" size="small" :inline="true" label-width="100px">
      <el-form-item label="平台订单号" prop="orderNo">
        <el-input v-model="form.orderNo" placeholder="请输入平台订单号" clearable/>
      </el-form-item>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button type="success" @click="callBack">回调</el-button>
      <el-button @click="cancel">取 消</el-button>
    </el-form>
    <el-table :data="rateList" @selection-change="handleSelectionChange">
      <el-table-column label="创建时间" align="center" prop="createdTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createdTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付时间" align="center" prop="payTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.payTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平台订单号" align="center" prop="orderNo"/>
      <el-table-column label="商家订单号" align="center" prop="merchantOrderNo"/>
      <el-table-column label="通道号" align="center" prop="channelNo" width="60"/>
      <el-table-column label="交易金额" align="center" prop="amount" width="80"/>
      <el-table-column label="订单状态" align="center" prop="status" width="80" v-if="isTest==true">
        <template slot-scope="scope">
          <order-status-list :form="form" search-type="payOut"/>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" align="center" prop="status" width="80" v-if="isTest==false" />
    </el-table>
  </div>
</template>

<script>
import MerchantList from "@/components/Query/merchantList";
import {callBack, getInfo} from "@/api/order/payment";
import OrderStatusList from "@/components/Query/OrderStatusList";

export default {
  name: "callback",
  props: ['isTest'],
  components: {OrderStatusList, MerchantList},
  data() {
    return {
      rateList: [],
      form: {
        merchantId: null
      },
      // 选中数组
      items: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
    }
  },
  methods: {
    rest() {
      this.form = {
        merchantId: null
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.items = selection.map(item => item)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    cancel() {
      this.$emit('cancel')
    },
    search() {
      getInfo(this.form.orderNo, {'isTest': this.isTest}).then(res => {
        if (!(res.data && res.data.length)) {
          this.$modal.msgWarning("无订单数据");
        }
        this.rateList = res.data
      }).catch(err => {
      })
    },
    callBack() {
      if (this.rateList && this.rateList.length > 0) {
        callBack(this.form.orderNo, {'isTest': this.isTest, 'status': this.form.status}).then(res => {
          this.$modal.msgSuccess(res.msg);
          this.cancel()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
