var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      !_vm.alisa
        ? _c(
            "el-select",
            {
              attrs: { clearable: "", filterable: "", placeholder: "请选择" },
              on: { change: _vm.selectChange },
              model: {
                value: _vm.form.channelNo,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "channelNo", $$v)
                },
                expression: "form.channelNo",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.alisa && _vm.alisa == "collectionDefaultChannel"
        ? _c(
            "el-select",
            {
              attrs: {
                multiple: "",
                clearable: "",
                filterable: "",
                placeholder: "请选择",
              },
              on: { change: _vm.selectChange },
              model: {
                value: _vm.form.collectionDefaultChannel,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "collectionDefaultChannel", $$v)
                },
                expression: "form.collectionDefaultChannel",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.alisa && _vm.alisa == "paymentDefaultChannel"
        ? _c(
            "el-select",
            {
              attrs: {
                multiple: "",
                clearable: "",
                filterable: "",
                placeholder: "请选择",
              },
              on: { change: _vm.selectChange },
              model: {
                value: _vm.form.paymentDefaultChannel,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "paymentDefaultChannel", $$v)
                },
                expression: "form.paymentDefaultChannel",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }