import request from '@/utils/request'

// 查询代付列表
export function listPayment(query) {
    return request({
        url: '/order/payment/list',
        method: 'get',
        params: query
    })
}

// 查询代付详细
export function getPayment(id) {
    return request({
        url: '/order/payment/' + id,
        method: 'get'
    })
}

// 订单号查询记录
export function getPaymentByOrderNo(orderNo) {
    return request({
        url: '/order/payment/orderNo/' + orderNo,
        method: 'get',
    });
}

// 查询三方状态
export function getPartnerStatus(orderNo) {
    return request({
        url: '/order/payment/three/' + orderNo,
        method: 'get',
        timeout: 10000000
    });
}

// 新增代付
export function addPayment(data) {
    return request({
        url: '/order/payment',
        method: 'post',
        data: data
    })
}

// 修改代付
export function updatePayment(data) {
    return request({
        url: '/order/payment',
        method: 'put',
        data: data
    })
}

// 删除代付
export function delPayment(id) {
    return request({
        url: '/order/payment/' + id,
        method: 'delete'
    })
}

// 查询代收详细
export function getInfo(id, query) {
    return request({
        url: '/order/payment/getInfo/' + id,
        method: 'get',
        params: query
    })
}

// 回调
export function callBack(id, query) {
    return request({
        url: '/order/payment/callBack/' + id,
        method: 'get',
        params: query
    })
}

// cranesPay回调
export function cranesPayCallback(data) {
    return request({
        url: '/order/payment/cranesPayCallback',
        method: 'post',
        data: data
    })
}

// 上传cranesPay回调
export function uploadCranesPayCallback(data) {
    return request({
        url: '/order/payment/uploadCranesPayCallback',
        method: 'put',
        data: data
    })
}

export function sync(data) {
    return request({
        url: '/order/payment/sync',
        method: 'get',
        data: data
    });
}

export function uploadPayOut(data) {
    return request({
        url: '/order/payment/uploadPayOut',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function changeChannelNo(channelNo, ids) {
    return request({
        url: '/order/payment/changeChannelNo/' + channelNo,
        method: 'post',
        data: ids
    })
}
